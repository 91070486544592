import { gql } from '@apollo/client';

const PatientListInfoShared = gql`
  fragment PatientListInfoShared on Patient {
    id
    firstName
    lastName
    dob
    includeEot
    scanReady
    settingsOverride
    hasPlan
    slug
    orders {
      id
    }
    referral {
      id
      user {
        id
        firstName
        lastName
        email
        slug
      }
    }
    createdAt
  }
`;

export default PatientListInfoShared;
