import { useApp, useLocale } from '@retainerclub/hooks';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { timestampFormatter } from '../../../transforms';
import RcDl from '../../RcDl/RcDl';
import RcLink from '../../RcLink/RcLink';
import ShowDetailsSection from './ShowDetailsSection';

function ShowDetails(props) {
  const { record } = props;

  const { locale } = useLocale();
  const { pathname } = useLocation();
  const notCustomerPath = !pathname.includes('/customers/');
  const { isAdmin } = useApp();

  return (
    <RcDl>
      {record.partnerOrganization && (
        <ShowDetailsSection title="Organization" className="sm:col-span-1">
          <RcLink
            to={`/partners/organizations/${record.partnerOrganization.slug}`}
          >
            {record.partnerOrganization.name}
          </RcLink>
        </ShowDetailsSection>
      )}

      {record.partnerLocation && (
        <ShowDetailsSection title="Location" className="sm:col-span-1">
          <RcLink to={`/partners/locations/${record.partnerLocation.slug}`}>
            {record.partnerLocation.name}
          </RcLink>
        </ShowDetailsSection>
      )}

      {record.adminLocation && isAdmin && (
        <ShowDetailsSection title="Location" className="sm:col-span-1">
          <RcLink to={`/admins/locations/${record?.adminLocation?.slug}/edit`}>
            {record?.adminLocation?.name ? record?.adminLocation?.name : 'N/A'}
          </RcLink>
        </ShowDetailsSection>
      )}

      {record.perArchEotFee !== null &&
        record.perArchEotFee !== undefined &&
        isAdmin && (
          <ShowDetailsSection
            title="EOT Fee (per arch)"
            className="sm:col-span-1"
          >
            {(record.perArchEotFee / 100).toLocaleString(locale, {
              style: 'currency',
              currency: 'USD',
            })}
          </ShowDetailsSection>
        )}

      {notCustomerPath && record?.roleType && (
        <ShowDetailsSection title="Role" className="sm:col-span-1">
          {record?.roleType?.name}
        </ShowDetailsSection>
      )}

      {record.priceBook && (
        <ShowDetailsSection title="Price Book" className="sm:col-span-1">
          <RcLink to={`/platform/price-books/${record.priceBook.slug}`}>
            {record.priceBook.name}
          </RcLink>
        </ShowDetailsSection>
      )}

      {record.account && (
        <ShowDetailsSection title="Account" className="sm:col-span-1">
          <RcLink to={`/customers/accounts/${record.account.slug}`}>
            {record.account.accountNumber}
          </RcLink>
        </ShowDetailsSection>
      )}

      {record.scannerBrand && (
        <ShowDetailsSection title="Scanner" className="sm:col-span-1">
          {record.scannerBrand.name}
        </ShowDetailsSection>
      )}

      {record.lastLoginAt && (
        <ShowDetailsSection title="Last Login" className="sm:col-span-1">
          <time dateTime={record.lastLoginAt}>
            {timestampFormatter(record.lastLoginAt)}
          </time>
        </ShowDetailsSection>
      )}

      {record.legacyId && isAdmin && (
        <ShowDetailsSection title="1.0 Legacy ID" className="sm:col-span-1">
          {record.legacyId}
        </ShowDetailsSection>
      )}

      <ShowDetailsSection title="Created At" className="sm:col-span-1">
        <time dateTime={record.createdAt}>
          {timestampFormatter(record.createdAt)}
        </time>
      </ShowDetailsSection>
      <ShowDetailsSection title="Last Updated" className="sm:col-span-1">
        <time dateTime={record.updatedAt}>
          {timestampFormatter(record.updatedAt)}
        </time>
      </ShowDetailsSection>
    </RcDl>
  );
}

ShowDetails.propTypes = {
  record: PropTypes.object.isRequired,
};

export default ShowDetails;
